import {Application} from 'stimulus';
import {definitionsFromContext} from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
//  application.load(definitionsFromContext(context))

addEventListener('DOMContentLoaded', (event) => {
  application.load(definitionsFromContext(context));
  $('body').removeClass('hide');
});
